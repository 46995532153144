import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
// Utility imports
import Seo from "@components/utility/SEO";
import Image from "@components/utility/Image";
import { graphql } from 'gatsby';
import { withArtDirection, getImage } from "gatsby-plugin-image";
// Content imports
import Isi from "@components/content/isi/Isi";

import "./styles.scss";


const ImportantSafetyInformation = ({ data }) => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--isi");
	}, []);

	const image_poly = getImage(data.poly);
	const image_sidebar = withArtDirection(getImage(data.sidebar), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.sidebarM),
		},
	]);

	return (
		<>
			<section>
				<div className="container container--inner">
					<h1 className="mb-5 heading heading-hero-blue">
						Important Safety Information for EMFLAZA<sup>&reg;</sup>{" "}
						<span className="lowercase">(deflazacort)</span>
					</h1>
				</div>
			</section>
			<section className="relative content-isi">
				<div className="container pb-16 container--inner md:pb-44">
					<Isi isFullIsiShown={true} />
				</div>
				<div className="absolute bottom-0 right-0 hidden md:block z-negative">
					<Image imageData={image_poly} alt="" />
				</div>
				<div className="section-sidebar">
					<Image
						imageData={image_sidebar}
						alt=""
					/>
				</div>
			</section>
		</>
	);
};

export default ImportantSafetyInformation;

export const pageQuery = graphql`
  query {
	poly: file(relativePath: {eq: "pages/isi/isi-poly.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	sidebar: file(relativePath: {eq: "pages/isi/isi-sidebar.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	sidebarM: file(relativePath: {eq: "pages/isi/isi-sidebar-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title="Important Safety Information - EMFLAZA® (deflazacort)"
			description="View Important Safety Information for EMFLAZA® (deflazacort). See full Prescribing Information."
			addSocialMeta={false}
		/>
	);
};
